import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { imageWrapper } from './about.module.css';

export const AboutContentGerman: React.FunctionComponent = () => {
    return (
        <>
            <h1>Über diese Seite</h1>
            <p>
                Hallo! Mein Name ist Alexander Lehner. Ich arbeite als Web-Entwickler
                und <a href="https://www.credly.com/badges/1839d107-b0d4-4788-ac7f-7d4905ed4f7e/" rel="noopener noreferrer">
                    zertifizierter Accessibility-Spezialist (CPACC)
                </a> in Wien, Österreich. Ich implementiere
                hauptsächlich Web-Applikationen mit Angular und React, aber bin stets offen für Neues. Besucht doch einfach
                meine <a href="https://github.com/alexlehner86" rel="noopener noreferrer">Github-Seite</a> oder
                folgt mir auf <a href="https://tech.lgbt/@alex86" rel="noopener noreferrer">Mastodon</a>.
            </p>
            <p>
                In diesem Blog beschäftige ich mich mit Web-Design, Barrierefreiheit, und noch viel mehr. Für mich ist
                Barrierefreiheit ein fundamentaler Bestandteil in der Web-Entwicklung: Semantisches Markup, Tastaturbedienbarkeit,
                Videos mit Untertitel, Farbkontraste und so weiter. Werden diese Anforderungen ignoriert, ist das für mich
                einfach schlechtes Design – und ein Zeichen der Ignoranz.
            </p>
            <p>
                In meiner Freizeit spiele ich gerne Brett- und Kartenspiele, gehe spazieren, mache eine Radtour,
                zocke Games auf meiner PS5 oder treffe Freunde auf ein Bier.
            </p>
            <div className={imageWrapper}>
                <StaticImage
                    src="../../images/alex_lehner_waldseilpark.jpg"
                    alt="Alexander Lehner in voller Montur in einem Waldseilpark"
                    placeholder="dominantColor"
                />
                <StaticImage
                    src="../../images/alex_with_playstation5.jpg"
                    alt="Ich wie ich meine geliebte, noch verpackte Playstation 5 in Händen halte"
                    placeholder="dominantColor"
                />
            </div>
            <h2>Über den Titel: „Oida, is des org“</h2>
            <p>
                Der Titel des Blogs ist ein Wortspiel mit dem Wiener Dialektwort „Oida“ und der Top-Level-Domain „.org“. Das Wort
                „Oida“ ist der Wienerische Ausdruck für „Alter“. Es weist eine große semantische Vielseitigkeit auf und kann
                Überraschung, Verwirrung bis hin zu Wut ausdrücken (siehe
                auch <a href="https://www.youtube.com/watch?v=iuXR53ex4iI" rel="noopener noreferrer">dieses Video</a>).
            </p>
            <p>
                Für mich der perfekte Ausdruck, um den Alltag eines Web-Entwicklers mit allen Höhen und Tiefen auszudrücken:
                „Alter, ist das krass!“
            </p>
        </>
    );
};
