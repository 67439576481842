import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { imageWrapper } from './about.module.css';

export const AboutContentEnglish: React.FunctionComponent = () => {
    return (
        <>
            <h1>About This Site</h1>
            <p>
                Hi! My name is Alexander Lehner. I'm a web developer
                and <a href="https://www.credly.com/badges/1839d107-b0d4-4788-ac7f-7d4905ed4f7e/" rel="noopener noreferrer">
                    Certified Accessibility Professional (CPACC)
                </a> from Vienna, Austria. I mainly implement web applications with Angular and React, but I'm always open to try something
                new. Go check out my <a href="https://github.com/alexlehner86" rel="noopener noreferrer">Github page</a> or
                follow me on <a href="https://tech.lgbt/@alex86" rel="noopener noreferrer">Mastodon</a>.
            </p>
            <p>
                In this blog, I talk about web design, accessibility, and much more.
                For me, accessibility is a fundamental part of web development: Using semantic markup,
                ensuring keyboard operability, providing captions, color contrast, and so on.
                Ignoring these requirements, for me, is just bad design – and a sign of ignorance.
            </p>
            <p>
                In my free time, I like to play tabletop and card games, go for a walk, ride my bike,
                play PS5 games, or meet friends to hang out.
            </p>
            <div className={imageWrapper}>
                <StaticImage
                    src="../../images/alex_lehner_waldseilpark.jpg"
                    alt="Alexander Lehner in full gear in a treetop rope park"
                    placeholder="dominantColor"
                />
                <StaticImage
                    src="../../images/alex_with_playstation5.jpg"
                    alt="Me holding my beloved Playstation 5 still in its cardboard box"
                    placeholder="dominantColor"
                />
            </div>
            <h2>About the Title: <span lang="de">“Oida, is des org”</span></h2>
            <p>
                The title of the blog is a pun on the Viennese dialect word <span lang="de">“Oida”</span> and the top-level domain “.org”.
                The word <span lang="de">“Oida”</span> is the Viennese expression for “dude”. It has great semantic versatility and can
                express surprise, confusion or even anger
                (see <a href="https://www.youtube.com/watch?v=iuXR53ex4iI" rel="noopener noreferrer">this video</a>).
            </p>
            <p>
                For me the perfect way to express the everyday life of a web developer with all its ups and downs:
                “Dude, that's crazy!”
            </p>
        </>
    );
};
